import Layout from '../components/Layout/Layout';
import React from 'react';
import './join-us.scss';
import Intro from '../components/Intro/Intro';
import { graphql, navigate } from 'gatsby';
import sprite from '../../static/sprite.svg';
import { GatsbyImage } from 'gatsby-plugin-image';
import Button from '../components/Button/Button';
import AmazonSmile from '../components/AmazonSmile/AmazonSmile';

const Help = ({ data }) => {
  return (
    <Layout>
      <Intro
        heading={data.introText.childTextContentJson.intro.heading}
        description={data.introText.childTextContentJson.intro.description}
        // imageData={data.introImage.childImageSharp.gatsbyImageData}
      />

      <section className="sponsor-a-smile">
        <Button
          label="Donate Now"
          center
          buttonStyle="SECONDARY"
          type="button"
          onClick={() => navigate('/donate')}
        />

        <Button
          label="Nominate a Patient"
          center
          buttonStyle="SECONDARY"
          type="button"
          onClick={() => navigate('/nominate-a-patient')}
        />

        {/* <div className="sponsor-a-smile__illustration">
          <span className="sponsor-a-smile__icon-box">
            <svg className="sponsor-a-smile__icon">
              <use href={`${sprite}#site-graphic`} />
            </svg>
          </span>
          <GatsbyImage
            image={data.sponsorASmileImage.childImageSharp.gatsbyImageData}
            alt="small steps illustration"
            class="sponsor-a-smile__image"
          />
        </div>
        <div className="sponsor-a-smile__text">
          <h2 className="sponsor-a-smile__heading heading-secondary">
            {data.sponsorASmileText.childTextContentJson.sponsorASmile.heading}
          </h2>
          <div
            className="sponsor-a-smile__description para"
            dangerouslySetInnerHTML={{
              __html:
                data.sponsorASmileText.childTextContentJson.sponsorASmile
                  .description,
            }}
          ></div>
          <Button
            label="Donate Now"
            type="button"
            onClick={() => navigate('/donate')}
          />
        </div> */}
      </section>

      {/* <section className="clinicians">
        <div className="clinicians__illustration">
          <GatsbyImage
            image={data.donationImage.childImageSharp.gatsbyImageData}
            alt="small steps illustration"
            class="clinicians__image"
          />
        </div>
        <div className="clinicians__text">
          <h2 className="sponsor-a-smile__heading heading-secondary">
            {data.donationsText.childTextContentJson.donations.heading}
          </h2>
          <div
            className="clinicians__description para"
            dangerouslySetInnerHTML={{
              __html:
                data.donationsText.childTextContentJson.donations.description,
            }}
          ></div>
          <Button
            label="U.S Clinicians - Nominate a Patient"
            type="button"
            onClick={() =>
              (window.location.href =
                'https://survey.alchemer.com/s3/6154889/Straumann-Group-Let-Them-Shine')
            }
          />
          <Button
            label="CAN Clinicians - Nominate a Patient"
            type="button"
            onClick={() =>
              (window.location.href =
                'https://survey.alchemer.com/s3/6154889/Straumann-Group-Let-Them-Shine')
            }
          />
        </div>
      </section> */}

      {/* <AmazonSmile /> */}
    </Layout>
  );
};

export default Help;

export const query = graphql`
  query HelpQuery {
    introText: file(relativePath: { eq: "join-us.json" }) {
      childTextContentJson {
        intro {
          heading
          description
        }
      }
    }
    introImage: file(relativePath: { eq: "help.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    sponsorASmileText: file(relativePath: { eq: "join-us.json" }) {
      childTextContentJson {
        sponsorASmile {
          heading
          description
        }
      }
    }
    sponsorASmileImage: file(relativePath: { eq: "sponsor-a-smile.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    donationImage: file(relativePath: { eq: "clinicians.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    amazonImage: file(relativePath: { eq: "amazon-smile.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    donationsText: file(relativePath: { eq: "join-us.json" }) {
      childTextContentJson {
        donations {
          heading
          description
        }
      }
    }
  }
`;
